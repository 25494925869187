import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Image from "react-bootstrap/Image";

import { BrandName, TextConfig } from './GlobalConfig';

import './App2.css'

import StNavbar from './components/StNavbar';
import StFooter from './components/StFooter';

	function StLanding() {

		return (
			
			<div>

				<StNavbar />	

				<div className='mt-0 mb-5 gradient-pastel jumbotron'>
					<Container className='flex-lg-row align-items-center'> 
						<Row className=''>
							<Col className="jumbotron-text">
								<div className="h1 mx-5">{TextConfig.heroTitle}</div>
								<div className="h2 mx-5">{TextConfig.heroDescription}</div>
								<Button className="mx-5 mt-3">Learn more</Button>
						</Col>
						<Col className="jumbotron-hero">
							<Image src="/hero-image-4.svg" className="img-fluid" />
						</Col>
						</Row>
					</Container>
				</div>

				<div id="features">
					<Container className="flex-wrap pt-4">
						<Row xs={1} md={3} className="g-4">
							{TextConfig.personas.map((persona) => (
								<Col>
									<Card className="px-4 py-4 d-flex flex-column">
										<Card.Body className="d-flex flex-column align-items-center">
											<Card.Title>
												<h3>{persona.usecase}</h3>
											</Card.Title>
											<Card.Text>
												<span className="h5 text-secondary mt-2">{persona.persona}</span>
											</Card.Text>

										</Card.Body>
									</Card>
								</Col>
							))}
						</Row>						
					</Container>
				</div>    


				<div className='mt-5 mb-5 landing-color-2 minitron d-flex align-items-center text-center'>
					<Container fluid>
						<p className="h3">{TextConfig.hiddenValue.value}</p>
						<p className="h5">{TextConfig.hiddenValue.how}</p>
					</Container>
				</div>			


				<div>
					<Container className="pt-5 mt-5 pb-2 mb-2 flex-wrap">
						<Row xs={1} md={3} className="g-4">
							{TextConfig.benefits.map((benefit) => (
								<Col>
									<Card className="px-4 d-flex flex-column">
										<Card.Body className="d-flex flex-column align-items-center">
											<Card.Title>
												<h3>{benefit.feature}</h3>
											</Card.Title>
											<Card.Text>
												<span className="h5 text-secondary mt-2">{benefit.value}</span>
											</Card.Text>

										</Card.Body>
									</Card>
								</Col>
							))}
						</Row>						
					</Container>
				</div>    

				<div id="pricing">
					<Container fluid className="border-0 pt-5 mt-5 pb-5 mb-5 x-gradient-pastel">
						<Row className="h3 d-flex justify-content-md-center pb-3">Pricing</Row>
						
						<Container className='mt-5'>
							<Row xs={1} md={3} className='g-4'>
							
								{TextConfig.pricing3col.map((plan) => (
									<Col>
										<Card className="d-flex flex-column p-2 border h-100 landing-color-2">
											<Card.Title className="h3 text-center pt-3">
												{plan.plan}
											</Card.Title>
											<p className=" text-center"> {plan.price} </p>
											<div className="text-center pb-3">
													<Button className="text-center" href={plan.subscriptionLink.buttonURL}>{plan.subscriptionLink.buttonName}</Button>
											</div>

											<ListGroup className='border-top'>
												{plan.planFeatures.map((featLine) => (
													<ListGroupItem className="p-2 m-2 text-center">
														{featLine}
													</ListGroupItem>

												))}
											</ListGroup>
												
												
											
											<Card.Footer className="d-flex flex-column">
												
											</Card.Footer>
										</Card>
									</Col>



								))}
								
							
							</Row>
						</Container>

					</Container>
				</div>


				<StFooter />

			</div>
		);
	}

	export default StLanding;
