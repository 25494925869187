function StError() {

    return (
        <div>
            <h3>
                We are sorry, this page does could not load. Please press the 'back' button or visit the <a href="/"> landing page </a>
            </h3>

        </div>

    );
}

export default StError;