export const TextConfig = {

	"brandName": "TurboSteed",
	
	"heroTitle": "Static website hosting",
	"heroDescription": "Upload your static websites and get running in minutes",

	"personas": [
		{
			"usecase": "Host Static Websites",
			"persona": "Ideal for small businesses, marketing pages or personal projects"
		},
		{
			"usecase": "Serve Single Page Apps",
			"persona": "Ideal for use cases such as dashboards, ecommerce or internal business applications"
		},
		{
			"usecase": "Showcase Your Work",
			"persona": "Ideal for students, developers or designers"
		}
	],
	"hiddenValue": {
		"value": "You focus on content while we manage your site",
		"how": "Leave the hosting and scaling the website to us",
	},
	"benefits": [
		{
			"feature": "Faster user experience",
			"value": "We will serve your website using a global Content Delivery Network"		
		},
		{
			"feature": "Custom Domains",
			"value": "Give your website a professional feel with custom domain"		
		},
		{
			"feature": "Built-in Analytics",
			"value": "Analyze your site traffic and tune your content for the best reach"		
		},
	],

	"pricing3col": [
		{
			"plan": "Free Plan",
			"price": "$0/month",
			"planFeatures": [
				"1 active project",
				"5 MB total size",
				"Basic Analytics",
				"5000 hits per month"
			],
			"subscriptionLink": {
				"buttonName": "Beta Access",
				"buttonURL": "/contactus"
			}
		},
		{
			"plan": "Basic Plan",
			"price": "Coming Soon",
			"planFeatures": [
				"2 active projects",
				"25 MB total size",
				"Basic Analytics",
				"25000 hits per month",
				"2 custom domains",
			],
			"subscriptionLink": {
				"buttonName": "Contact Us",
				"buttonURL": "/contactus"
			}
		},
		{
			"plan": "Startup plan",
			"price": "Coming Soon",
			"planFeatures": [
				"5 active projects",
				"150 MB total size",
				"Advanced Analytics",
				"150000 hits per month",
				"5 custom domains",
				"Custom 404 page"
			],
			"subscriptionLink": {
				"buttonName": "Contact Us",
				"buttonURL": "/contactus"
			}
		},

	
	],

	"secondaryValueProp": "Scale without worried",
	"secondaryValuePropDescription": "TurboSteed provieds you a hassle free, scalable and reliable platform so that you can focus on your work"
};

export const BrandName = "TurboSteed";
