import logo from './logo.svg';
//import './App.css';

import './cstyles.css'

import StLanding from './StLanding';

import Container from "react-bootstrap/container";
import Card from "react-bootstrap/Card";
import CardGroup from 'react-bootstrap/CardGroup';

function App() {
  return (
    <div>
	    <StLanding />  
    </div>
  );
}

export default App;
